.FAQ_comparison_cards {
  margin-top: 35px;
  text-align: center;
}

.ui.header.comparison_FAQs {
  margin-top: 50px;
  text-align: center;
}

.ui.basic.segment.FAQ_pt2 {
  margin-top: 100px;
}

.ui.header.FAQ_header_font {
  font-size: 3em !important;
}

.ui.card.card1 {
  /* background-color: #013850; */
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .7); 
}

.ui.card.card2 {
  /* background-color: #232729; */
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .7); 
}

.ui.card.card3 {
  /* background-color: #003820; */
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .7); 
}

.header.card1 {
  font-size: 1.75em !important;
  color: #fff !important;
}

.header.card2 {
  font-size: 1.75em !important;
  color: #fff !important;
}

.header.card3 {
  font-size: 1.75em !important;
  color: #fff !important;
}

.description.card1 {
  color: #fff !important;
  font-weight: bold;
}

.description.card2 {
  color: #fff !important;
  font-weight: bold;
}

.description.card3 {
  color: #fff !important;
  font-weight: bold;
}

.contact-container {
  margin-top: 90px;
}

.ui.header.times {
  display: block;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.1em;
  font-weight: 900;
  text-decoration: underline;
}

.ui.header.name {
  display: block;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.1em;
  font-weight: 900;
}

.contact_center {
  text-align: center;
}

.staticBG {
  width: 100vw;
  margin: 0 !important;
}

.form_frost {
  padding-top: 50px;
  padding-bottom: 25px;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); 
  border-radius: 5px;
  position: relative;
  z-index: 1;
  background: inherit;
  overflow: hidden;
}

.form_frost:before {
  content: "";
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 15%);
  background-color: rgb(225 225 225 / 75%);
  filter: blur(20px);
  margin: -20px;
}

.ui.input {
  opacity: 0.5;
}

.ui.form textarea {
  opacity: 0.5;
}

.ui.selection.dropdown {
  opacity: 0.5;
}

.overflow_form {
  height: 100vh;
}

.confirmations {
  padding: 15%
}

.ui.active.visible.selection.dropdown {
  opacity: 1.0;
}