.segment1_homepage {
  padding: 8em 0em !important;
}

.homepage_header_font {
  font-size: 2em !important;
}

.homepage_text {
  font-size: 1.33em !important;
}

.review_cards {
  padding: 3em 1em !important;
}

.exception {
  font-size: 0.8em;
  margin-bottom: 0.3rem !important;
}

.ui.container.exceptions {
  margin-top: 15em !important;
  /* padding: 0em 3em !important; */
}

@media (min-height: 625px) {
  .ui.container.exceptions {
    margin-top: 23em !important;
  }
}

@media (min-height: 675px) {
  .ui.container.exceptions {
    margin-top: 15em !important;
  }
}
@media (min-height: 768px) {
  .ui.container.exceptions {
    margin-top: 7em !important;
  }
}
@media (min-height: 868px) {
  .ui.container.exceptions {
    margin-top: 0em !important;
  }
}
@media (min-height: 992px) {
  .ui.container.exceptions {
    margin-top: 0em !important;
  }
}
.flipCard {
  z-index: 1000;
}
