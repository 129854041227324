body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.homepage_container {
  height: 100vh;
  width: 100vw;
}
.footer {
  padding: 2em !important;
}
.icons {
  text-decoration: none !important;
}
@media (min-width: 992px) {
  .container-fluid {
    width: 86% !important;
  }
}
.navbar.navbar-expand-lg.navbar-dark.fixed-top {
  background: rgba(0, 0, 0, 0.9);
}
.navbar.colorChange.navbar-expand-lg.navbar-dark.fixed-top {
  background: rgba(0, 0, 0, 0.9);
}
@media (min-width: 992px){
  .navbar.navbar-expand-lg.navbar-dark.fixed-top {
    background: linear-gradient(to left, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.05));
  }}

  @media (min-width: 992px){
    .navbar.colorChange.navbar-expand-lg.navbar-dark.fixed-top {
      background: linear-gradient(to left, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.15));
    }}

.navbar-collapse {
  justify-content: flex-end;
}
.navbar-nav {
  padding-right: 2%;
}
.nav-link {
  color: white;
  font-size: medium;
}


