.flip-container {
  height: 150vh;
}
@media (min-width: 768px) {
  .flip-container {
    height: 45vh;
  }
}

.flip-card-outer {
  width: 300px;
  height: 400px;
  perspective: 1000px;
  margin: 25px 0;

  &.hover:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-inner {
    z-index: 10;
    backface-visibility: visible;
    transform-style: preserve-3d;
    transition: 0.5s linear 0.1s;
    position: relative;
    width: inherit;
    height: inherit;

    // &.showBack {
    //   transform: rotateY(180deg);
    // }

    .card {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      border: 0;
      backface-visibility: hidden;

      &.front {
        z-index: 9;
        transform: rotateY(0);
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;

        .frost {
          top: 3px;
          text-align: center;
          width: 21rem;
          height: 7em;
          box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); 
          border-radius: 5px;
          position: fixed;
          z-index: 1;
          background: inherit;
          overflow: hidden;
        }

        .frost:before {
          content: "";
          position: absolute;
          background: inherit;
          z-index: -1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          box-shadow: inset 0 0 200px rgba(255, 255, 255, .5);
          filter: blur(5px);
          margin: -10px;
        }

        .logo {
          position: relative;
          top: 4px;
        }

        .card-wording {
          position: fixed;
          left: 13%;
          top: 30%;
        }

        p {
          margin-bottom: 0;
        }

        .name {
          font-size: 1.4rem;
        }

        .price {
          position: relative;
          font-size: 1.8rem;
          font-weight: bold;
          margin-top: 310px;
        }

        .icon {
          position: absolute;
          bottom: 5px;
          right: 5px;
          transform: rotateY(180deg);
        }
      }

      &.back {
        z-index: 8;
        transform: rotateY(180deg);
        background-size: cover;
        color: #fff;

        .card-wording {
          position: fixed;
          top: 20%;
          right: 5%;
          left: 5%;
          display: inline-block;
        }

        .logo {
          position: absolute;
          top: 2px;
        }

        p {
          margin-bottom: 0;
        }

        .brand {
          font-size: 2rem;
          font-weight: bold;
        }

        .name {
          font-size: 1rem;
        }

        .btn {
          position: relative;
          font-weight: bold;
          margin-top: 320px;
        }

        .icon {
          position: absolute;
          bottom: 5px;
          right: 5px;
          transform: rotateY(180deg);
        }
      }
    }
  }
}
