.header1 {
  font-size: 3em !important;
  font-weight: normal;
  margin-bottom: 0;
}

.header2 {
  font-size: 1.7em !important;
  font-weight: normal;
  margin-bottom: 1% !important;
}

.segment1_homepage {
  padding: 8em 0em !important;
}

.ui.segment.full_width {
  margin: 0em !important;
  padding-left: 7% !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  color: white !important;
  bottom: 0 !important;
  position: absolute !important;
  width: 100% !important;
}
@media (max-width: 767px) {
  .ui.segment.full_width {
    display: none !important;
  }
}

.ui.text.container.ui.container.header_textBlock {
    margin-top: 45%;
}

@media (min-width: 675px) {
    .ui.text.container.ui.container.header_textBlock {
        margin-top: 35%;
        margin-left: 5% !important;
    }
  }
  @media (min-width: 992px) {
    .ui.text.container.ui.container.header_textBlock {
        margin-top: 20%;
        margin-left: 7% !important;
    }
  }
.ui.image {
    justify-content: center !important;
}

