.product-container {
  margin-top: 120px;
  margin-bottom: 60px;
}
.ui.header.name {
  display: block;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.1em;
  font-weight: 900;
}
.ui.header.times {
  display: block;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.1em;
  font-weight: 900;
  text-decoration: underline;
}

.ui.header.comparison_FAQs {
  margin-top: 50px;
  text-align: center;
}

.ui.basic.segment.FAQ_pt2 {
  margin-top: 100px;
}

.ui.header.FAQ_header_font {
  font-size: 3em !important;
}

.finePrint {
  font-size: 0.75em;
}

.right.floated.six.wide.column.cardFinePrint {
  align-self: flex-end !important;
}

.ui.header.card_header_font {
  color: #fff;
}

.card_button {
  padding-top: 35px;
}

.ui.container.extra_footer_RTLG {
  /* background-color: #013850; */
  width: 100vw;
  color: #fff;
  padding-top: 90px;
  padding-bottom: 90px;
}

.ui.header.extra_footer_RTLG {
  color: #fff;
}

.ui.fluid.card.sign_up_card_RTLG {
  color: #fff;
  padding-top: 90px;
  padding-bottom: 90px;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .7); 
}

.ui.container.extra_footer_RTP {
  background-color: #232729;
  width: 100vw;
  color: #fff;
  padding-top: 90px;
  padding-bottom: 90px;
}

.ui.header.extra_footer_RTP {
  color: #fff;
}

.ui.fluid.card.sign_up_card_RTP {
  color: #fff;
  padding-top: 90px;
  padding-bottom: 90px;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .7); 
}

.ui.container.extra_footer_AE {
  background-color: #003820;
  width: 100vw;
  color: #fff;
  padding-top: 90px;
  padding-bottom: 90px;
}

.ui.header.extra_footer_AE {
  color: #fff;
}

.ui.fluid.card.sign_up_card_AE {
  color: #fff;
  padding-top: 90px;
  padding-bottom: 90px;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .7); 
}
.ui.header.card_header_font.card_header2 {
  margin-top: 0;
  margin-bottom: 1em;
}
.ui.header.card_header_font {
  margin: 0;
}
.ui.header.extra_footer_AE.no_margin {
  margin: 0;
}

.ui.header.extra_footer_AE.no_bottom_margin {
  margin-bottom: 0;
}

.ui.primary.button.RTLG_button {
  background-color: #006c9c;
  box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, .5); 
}

.ui.primary.button.RTP_button {
  background-color: #6b757c;
  box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, .5); 
}

.ui.primary.button.AE_button {
  background-color: #007043;
  box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, .5); 
}

.ui.black.button {
  box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, .5); 
}
.column.header_button {
  align-self: flex-end !important;
}